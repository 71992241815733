import React from 'react'


const RegisterButton = ({...rest }) => {

    
    return (
        <a className="red-rounded-button" 
              href="https://www.youtube.com/playlist?list=PLMc7NR20hA-KF8c_hVICKpzKnWkjzfC2V"
              target="_blank" rel="noreferrer"
              {...rest}
              >Watch on demand</a>
    )
}


export default RegisterButton

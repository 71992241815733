import React from "react"
import { graphql, Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Layout from "../layout/layout"
import SEO from "../layout/seo"

import './sessions.scss'
import RegisterButton from "../components/RegisterButton"

export default ({ data, pageContext }) => {
    const spkr = data.allSessionizeSpeakersJson.edges[0].node;

    const { session } = pageContext;

    return (
        <Layout title={session.title}>
            <SEO title={session.title} />
            <div className="container" style={{
                paddingTop: `2rem`
            }}>
                
                <AnchorLink to={`/schedule#timeline`} style={{fontSize: `18px`, textDecoration: `none`}}>&lt;&nbsp;&nbsp;Return to Schedule</AnchorLink>
                
                <div className="session-wrapper"  >
                

                    <p>{session.description}</p>

                    
                    {data.allSessionizeSpeakersJson.edges.map(spkr => (
                    <div className="session-speaker" key={spkr.node.fullName.toString()}>
                        <div className="img-wrapper"><img alt={spkr.node.fullName} src={spkr.node.profilePicture} /></div>
                        <div className="txt-wrapper">
                            <h3>{spkr.node.fullName}</h3>
                            <div className="tagline">{spkr.node.tagLine}</div>
                            <p>{spkr.node.bio}</p>
                        </div>
                    </div>
                    ))}
                
                
                <div style={{
                    padding: `2rem 0`,
                    textAlign: `center`
                }}><RegisterButton /></div>
                
                </div>

                
            </div>
        </Layout>
    )
}

export const query = graphql`
query($speakerId: [String!]!) {
    allSessionizeSpeakersJson(filter: {id: {in: $speakerId}}) {
      edges {
        node {
          bio
          fullName
          tagLine
          profilePicture
        }
      }
    }
  }  
`

